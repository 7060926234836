<section>
  <div class="container py-5">
    <div class="row py-5">
      <div class="col-12 text-center">
        <h2 class="">Registration</h2>
      </div>
      <!-- col end-->
    </div>
    <br /><br />
    <!-- row end-->
    <div class="row">
      <br /><br /><br /><br /><br /><br />
      <div class="col-lg-12 text-center">
        <h4>To be updated soon</h4>
      </div>
    </div>
  </div>
</section>
